@import 'variables';

.nfcv-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background: $white;

    @media screen and (max-width: 960px) {
        height: 100px;
    }
}

.nfcv-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 16px;

    img {
        display: block; 
        max-width: 100%; 
        max-height: 50px;
        margin: 0 auto; 
        object-fit: contain;

        @media screen and (max-width: 960px) {
            max-height: 75px;
        }
    }
}

.nfcv-box {
    display: flex;
    flex-direction: column;
    width: 86vw;
    max-width: 340px;
    margin: 30px auto 0;
    padding: 50px 30px 30px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    &-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        &--spacer {
            margin-top: 14px;
        }
    }

    &-label {
        display: block;
        width: calc(40% - 10px);
        margin-right: 10px;
        font-family: "Raleway", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;

        &--link {
            width: 100%;
            margin-top: 20px;
            text-decoration: underline;
        }
    }

    &-value {
        display: block;
        width: 60%;
        font-family: "Raleway", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
    }
}

