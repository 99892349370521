@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import 'intro';
@import 'footer';

html {
    scroll-behavior: initial;
}

html,
body {
    max-width: 100%;
    min-height: 100vh;
    height: auto;
    background: $blue;
    color: $black;
}

.nfcv-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
