/* Imported from NFC Clip */
.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.feedback-bottom-nav {
    max-width: 100vw;
    margin-bottom: 0;
    margin-top: auto;
    margin-left: 0;
    margin-right: 0;
    background: white;
}

.feedback-bottom-nav div {
    color: white;
    font-size: 10px;
}

.branded_logo.feedback-bottom-nav a {
    color: #1C75BC;
}

/* Added */

.nfcv-footer {
    &-span {
        display:inline-block;
        font-size:12px; 
    }

    &-img-wrapper {
        display:inline-block;  
        width:auto;

        img {
            max-width: 60px;
        }
    }
}